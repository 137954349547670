<template>
     <el-menu
        class="el-menu-vertical-demo"
        menu-trigger="hover"
        @open="handleOpen"
        @close="handleClose"
        text-color="#262626"
        :default-active="$route.path"
        active-text-color="#28A5DC"
        router
        >
        <el-menu-item index="/admin/home">
            <!-- <i class="el-icon-s-home"></i> -->
            <img class="noActive" src="../../assets/imgs/home1.png" alt="">
            <img class="isActive" src="../../assets/imgs/home2.png" alt="">
            <span slot="title">首页</span>
        </el-menu-item>
        <el-submenu index="1" class="setImg" v-if="false">
            <template slot="title">
                <!-- <i class="el-icon-s-claim"></i> -->
                <img class="noActive" src="../../assets/imgs/cons1.png" alt="">
                <img class="isActive" src="../../assets/imgs/cons2.png" alt="">
                <span>流量合作</span>
            </template>
            <el-menu-item index="/admin/media">我的媒体</el-menu-item>
            <el-menu-item index="/admin/advposition">我的广告位</el-menu-item>
        </el-submenu>
        <el-menu-item index="/admin/datareport">
            <!-- <i class="el-icon-menu"></i> -->
            <img class="noActive" src="../../assets/imgs/data1.png" alt="">
            <img class="isActive" src="../../assets/imgs/data2.png" alt="">
            <span slot="title">数据报表</span>
        </el-menu-item>
        <el-submenu index="2">
            <template slot="title">
                <!-- <i class="el-icon-s-claim"></i> -->
                <img class="noActive" src="../../assets/imgs/account1.png" alt="">
                <img class="isActive" src="../../assets/imgs/account2.png" alt="">
                <span>结算管理</span>
            </template>
            <el-menu-item index="/admin/financialinfo" >财务信息</el-menu-item>
            <el-menu-item index="/admin/brokerage">分佣收益</el-menu-item>
        </el-submenu>
        <el-menu-item index="/admin/msgcenter">
            <!-- <i class="el-icon-message-solid"></i> -->
            <img class="noActive" src="../../assets/imgs/msg1.png" alt="">
            <img class="isActive" src="../../assets/imgs/msg2.png" alt="">
            <span slot="title">消息中心</span>
        </el-menu-item>
    </el-menu>
</template>

<script>

    export default {
        data() {
            return {
                activerouter: ''
            }
        },
        methods: {
            handleOpen(key,keyPath) {
                // console.log(key,keyPath)
            },
            handleClose(key,keyPath){
                // console.log(key,keyPath)
            }
        },
    }
</script>

<style lang="less" scoped>
    .el-submenu .el-menu-item{
        min-width: 120px !important;
    }
    .isActive{
        display: none;
    }
    .setImg{
        img{
            width: 20px;
            position: relative;
            left: -3px;
            margin-right: 5px !important;
        }
        // position: relative;
        // left: -4px;
    }
    .el-menu-vertical-demo{
        img{
            margin-right: 9px;
        }
        .is-active{
            .isActive{
                display: inline-block;
            }
            .noActive{
                display: none;
            }
        }
    }
</style>